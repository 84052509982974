<template>
  <div>
    <div v-if="loadingCategory">
      <v-skeleton-loader
        tile
        class="pb-10 skeleton_headercategory"
        type="image"
        height="283"
      ></v-skeleton-loader>
    </div>
    <div v-if="!loadingCategory">
      <v-card tile class="py-0 white--text" :color="category.color">
        <v-container>
          <div
            class="triangle corner_header mb-0 font-weight-black white--text"
            :class="category.color + '_color'"
          >
            {{ category.num }}
          </div>

          <v-row class="align-center pt-12 pb-4">
            <v-col cols="12" :md="6" class="py-0">
              <h1 class="py-0 white--text">
                {{ category.name }}
              </h1>
            </v-col>
            <v-col cols="12" :md="2" class="py-0 mx-3 mx-md-0">
              <v-row>
                <div class="d-flex align-baseline">
                  <span class="font-weight-bold display-1 bigNumber">
                    {{ category.nbarticles }}
                  </span>
                  <span class="white--text pl-3 body-1 font-weight-medium">
                    {{ $tc('general.card_number', category.nbarticles) }}
                  </span>
                </div>
              </v-row>
            </v-col>

            <v-col cols="12" :md="4" class="py-0 mt-5 mt-md-0">
              <v-card-subtitle class="pa-0 pb-2 white--text">
                {{ $t('general.progress') }}
              </v-card-subtitle>
              <v-card-text class="pa-0">
                <v-progress-linear
                  class="progressbar_custom articlewhite"
                  background-color="white"
                  :color="category.color"
                  rounded
                  height="45"
                  :value="score"
                >
                  <template v-slot="{ value }">
                    <strong class="grey--text text--darken-2"
                      >{{ Math.ceil(value)
                      }}<span class="percent">%</span></strong
                    >
                  </template>
                </v-progress-linear>
              </v-card-text>
            </v-col>
          </v-row>

          <v-text-field
            v-model="query"
            solo
            flat
            dense
            append-icon="mdi-magnify"
            type="text"
            @keyup.enter="sendMessage"
            class="searchbar"
            label="Recherche"
          ></v-text-field>
        </v-container>
      </v-card>
    </div>

    <div v-if="!loadingCategory">
      <v-container>
        <v-row>
          <v-col
            cols="6"
            :sm="4"
            :md="3"
            :lg="2"
            :xl="2"
            v-for="(article, i) in category.articles"
            :key="article.id + i"
            :article="article"
          >
            <ArticleTeaser :article="article" />
          </v-col>
          <v-col
            cols="6"
            :sm="4"
            :md="3"
            :lg="2"
            :xl="2"
            v-for="(article, i) in category.linked_articles"
            :key="article.id + i"
            :article="article"
          >
            <LinkedArticleTeaser :article="article" />
          </v-col>
        </v-row>
      </v-container>
      <CategorySubcategory
        v-for="subcategory in category.subcategories"
        :key="'subcat_' + subcategory.id"
        :subcategory="subcategory"
      />
    </div>

    <div v-if="loadingCategory">
      <v-container v-for="i in 8" :key="i" class="mb-10">
        <v-row>
          <v-col cols="12" :sm="6" :md="4" :lg="2" :xl="2" class="pb-0">
            <v-skeleton-loader class="pb-3" type="text"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            :sm="4"
            :md="3"
            :lg="2"
            :xl="2"
            v-for="i in 8"
            :key="i"
          >
            <v-skeleton-loader class="" type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-divider></v-divider>

    <v-container>
      <v-row class="px-5">
        <v-col cols="12" class="pb-0">
          <v-subheader
            class="subtitle-1 font-weight-medium grey--text pa-0"
            v-html="$t('article.main_categories')"
          ></v-subheader>
        </v-col>
      </v-row>
      <v-row v-if="!loadingCategory" class="px-5">
        <v-col
          cols="12"
          :sm="6"
          :md="4"
          :lg="3"
          :xl="2"
          v-for="category in categories"
          :key="category.id"
        >
          <CategoryTeaserSelected
            :category="category"
            v-if="category.id === id"
          />
          <CategoryTeaser :category="category" v-if="category.id !== id" />
        </v-col>
      </v-row>
      <v-row v-if="loadingCategory">
        <v-col
          cols="12"
          :sm="6"
          :md="4"
          :lg="3"
          :xl="2"
          v-for="i in 8"
          :key="i"
        >
          <v-skeleton-loader
            class="ma-0 pa-0 skeleton_categoryteaser"
            type="image"
            height="174"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CategorySubcategory from '@/components/CategorySubcategory'
import CategoryTeaser from '@/components/CategoryTeaser'
import ArticleTeaser from '@/components/ArticleTeaser'
import LinkedArticleTeaser from '@/components/LinkedArticleTeaser'
import CategoryTeaserSelected from '@/components/CategoryTeaserSelected'

import api from '@/services/api'

export default {
  props: ['id'],
  data() {
    return {
      category: Object,
      subcategories: [],
      categories: [],
      loadingCategory: true,
      articles: [],
      query: '',
      score: 0,
    }
  },
  methods: {
    sendMessage() {
      this.$router.push({ name: 'search', params: { query: this.query } })
    },
  },
  created() {
    api
      .getMainCategory(this.id, this.$store.getters.getProfile.uid)
      .then((response) => {
        this.category = response.data
        this.loadingCategory = false
        api.getScore(this.$store.getters.getProfile.uid).then((response) => {
          this.score = response.data.categories[this.category.id]
            ? response.data.categories[this.category.id].score
            : 0
        })
      })

    api
      .getMainCategories(this.$store.getters.getProfile.uid)
      .then((response) => {
        this.categories = response.data
      })
  },
  computed: {},
  components: {
    CategorySubcategory,
    CategoryTeaser,
    CategoryTeaserSelected,
    ArticleTeaser,
    LinkedArticleTeaser
  },
}
</script>
<style lang="scss">
@import 'src/assets/css/progressbar.scss';

.triangle {
  &.corner_header {
    position: absolute;
    top: 7px;
    left: 15px;
    z-index: 1;
    font-size: 24px;
    font-family: 'Lora', serif !important;

    &:before {
      content: '';
      position: absolute;
      top: -7px;
      left: -15px;
      z-index: -1;
      border-style: solid;
      border-width: 80px 80px 0 0;
    }
  }
}

.skeleton_headercategory {
  .v-skeleton-loader__image {
    height: 283px;
  }
}

.skeleton_search {
  border-radius: 50px;

  .v-skeleton-loader__card .v-skeleton-loader__image {
    border-radius: 50px;
  }
  .v-skeleton-loader__image {
    height: 48px;
  }
}
.search-container {
  border-radius: 50px;
}
</style>
