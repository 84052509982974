<template>
	<v-card class="flex footer" tile>
		<v-row>
			<v-col lg="3" md="6" sm="12" class="d-flex align-center justify-center">
				<a href="https://www3.unifr.ch/med/imf" target="_blank">
					<v-img
						src="@/assets/img/unifr_logo.png"
						width="130"
						contain
					></v-img>
				</a>
			</v-col>
			<v-col lg="3" md="6" sm="12" class="block-col">
				<a
					href="mailto:frimed@unifr.ch"
					target="_blank"
					class="
						contact-button
						font-weight-medium
						p-0
					"
				>
					{{ $t("general.contact_us") }}
				</a>
				<a class="about-link" @click.prevent="$emit('openPopup')">
					{{ $t("general.about") }}
				</a>
			</v-col>
			<v-col lg="3" md="6" sm="12" class="d-flex align-center justify-center">
				<a href="https://frimed.ch/" target="_blank" class="frimed-button font-weight-medium">
					<v-img src="@/assets/img/frimed_logo.png" contain class="frimed-button__logo"></v-img>
					<span>Frimed.ch</span>
				</a>
			</v-col>
			<v-col lg="3" md="6" sm="12" class="d-flex align-center justify-center">
				<a href="https://www3.unifr.ch/med/imf/fr/formation/harcelement" target="_blank">
					<v-img
						src="@/assets/img/logo_harcelement.jpg"
						width="180"
						contain
					></v-img>
				</a>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { i18n } from '../plugins/i18n';

export default {
	data: () => ({
		icons: ["mdi-facebook-box", "mdi-twitter-box", "mdi-instagram"],
	}),
};
</script>

<style lang="scss">
.footer {
	padding: 1.5em 5em;
}

.v-list-item {
	min-height: 25px;
}

.contact-button {
	font-size: 18px;
	background: #000;
	color: #fff !important;
	width: 50%;
	padding: 7px;
	margin-bottom: 1rem;
	text-align: center;
	transition: background 150ms ease;
	text-decoration: none;
	min-width: 150px;
	max-width: 200px;

	&:hover {
		background: lighten(#000, 20%);
	}
}

.block-col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 50px;
}

.about-link {
	text-decoration: none;
	color: lighten(#000, 60%) !important;
}

.frimed-button {
	background: #008A5A;
	color: #fff !important;
	text-decoration: none;
	padding: 10px 7px;
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	font-size: 18px;
	transition: background 100ms ease;
	min-width: 130px;
	max-width: 190px;

	&:hover {
		background: darken(#008A5A, 5%);
	}

	&__logo {
		width: 30px;
		max-width: 30px;
		background: #fff;
		padding: 5px;
	}

	span {
		margin-left: 0.3rem;
	}
}
</style>
