<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card style="padding:0 30px;">
      <v-card-text>
        <h1 class="mt-10">
          Tectum – Die Plattform zur Unterstützung der Lehre, die vom Institut
          für Hausarztmedizin der Universität Freiburg entwickelt wurde
        </h1>

        <p class="mt-2">
          Diese Plattform steht Studierenden und Praxislehrärtzinnen und Ärzte
          in zwei Sprachen zur Verfügung und kann auf verschiedenen Stufen
          angewendet werden:
        </p>
        <h2 class="mt-7">
          1. Lernfelder der Hausarztmedizin während des Praktikums in der Praxis
          definieren
        </h2>
        <p class="mt-2">
          <strong>Tectum</strong> ermöglicht den Sprung von einer Ausbildung
          nach dem Zufallsprinzip bei den Konsultationen zu einer proaktiven
          Ausbildung, die sich auf die Lernbedürfnisse konzentriert. Es wird
          eine breite Palette von Gesundheitsproblemen angesprochen, wodurch die
          verschiedenen Felder der Hausarztmedizin durchlaufen werden können.
          Für die Studierenden entspricht dieser Aspekt den Zielen der SSP
          (situations as starting point) des Profiles.
        </p>
        <h2 class="mt-7">2. Spezifische Lernziele</h2>
        created
        <p class="mt-2">
          Jeder Artikel wird durch spezifische Lernziele eingeleitet. Sie
          ermöglichen auf wichtige Aspekte wie rote Flaggen, Schlüsselelemente
          der Anamnese oder die Differenzialdiagnose zu achten.
        </p>
        <h2>3. Schulungsunterlage, die erweitert werden können</h2>
        <p class="mt-2">
          In Tectum enthält jeder Artikel spezifisches Schulungsunterlage
          und/oder Artikeln, die für die Arbeit in der Hausarztpraxis als
          relevant eingeschätzt werden. Dank Ihres Beitrags als Nutzer·in kann
          der Inhalt jedes Themas angereichert werden. Zögern Sie nicht, uns
          Artikel zu übermitteln, die Sie für wichtig halten (mit autorisiertem
          Zugang), um Tectum weiterzuentwickeln.
        </p>
        <p class="mt-5">
          <i
            >Der Reichtum Tectums liegt in der Beteiligung der gesamten
            Gemeinschaft von Lehrerinnen und Lehrern und Studierenden, um zu
            ihrer Verbesserung beizutragen. Zusammen werden wir dieses
            dynamische und praktische Hilfsmittel für den medizinischen Alltag
            mitgestalten und weiterentwickeln.</i
          >
        </p>
      </v-card-text>

      <v-card-actions class="mt-0 pt-0 d-flex justify-center actions">
        <v-btn class="ma-2" @click.prevent="close()">
          Ok
          <v-icon right dark>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: 0,
    }
  },
  methods: {
    close() {
      this.$store.dispatch('SET_DIALOG')
      this.dialog = 0
    },
  },
  created() {
    this.dialog = this.$store.getters.getDialog
  },
  components: {},
}
</script>
<style lang="scss">
h1 {
  line-height: 30px;
}
h2 {
  line-height: 25px;
}
.actions {
  height: 100px !important;
}
</style>
