<template>
  <v-card :to="{ name: 'category', params: { id: category.id } }">
    <v-container class="py-4 px-0">
      <div
        class="triangle corner_articleteaser mb-0 font-weight-black white--text"
        :class="category.color + '_color'"
      >
        {{ category.num }}
      </div>
      <v-row>
        <v-col cols="9" class="pa-0 pl-3">
          <v-list-item class="pa-0">
            <v-list-item-content class="py-0 align-end" style="height:120px">
              <!-- <v-progress-linear
                class="mb-4 progressbar_teaser"
                background-color="transparent"
                :color="category.field_color"
                height="20"
                value="22"
              >
                <template v-slot="{ value }">
                  <strong :class="category.field_color + '--text'"
                    >{{ Math.ceil(value)
                    }}<span class="percent">%</span></strong
                  >
                </template>
              </v-progress-linear> -->
              <p
                :class="
                  category.color +
                    '--text' +
                    ' mb-0' +
                    ' ml-5' +
                    ' align-self-end' +
                    ' subtitle-2' +
                    ' d-inline-block' +
                    ' text-truncate'
                "
                style="max-width: calc(100% - 20px)"
              >
                {{ category.name }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="2" class="pa-0 align-self-end">
          <v-list-item class="pa-0">
            <v-list-item-content class="pb-0 mr-5">
              <v-icon medium :color="category.color">
                mdi-arrow-right
              </v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    category: Object,
  },
}
</script>

<style lang="scss">
@import 'src/assets/css/progressbar.scss';

.triangle {
  &.corner_articleteaser {
    position: absolute;
    top: 2px;
    left: 10px;
    z-index: 1;
    font-size: 20px;
    font-family: 'Lora', serif !important;

    &:before {
      content: '';
      position: absolute;
      top: -2px;
      left: -10px;
      z-index: -1;
      border-style: solid;
      border-width: 60px 60px 0 0;
    }
  }
}
</style>
