<template>
  <v-container class="px-0 py-0">
    <v-card
      :to="{ name: 'article', params: { id: article.id } }"
      class="ma-xs-12 principal"
    >
      <v-responsive :aspect-ratio="1" v-if="article.illustration">
        <v-img
          :src="article.illustration"
          class="d-flex align-end"
          aspect-ratio="1"
          contain
        >
          <v-card-actions
            class="flex-column justify-end align-end"
            min-width="100%"
            aspect-ratio="1">
          </v-card-actions>
        </v-img>
      </v-responsive>
      <h3 class="subtitle-1 text-center py-3" :class="article.color + '--text'" v-if="!article.illustration">{{ article.title }}</h3>
    </v-card>
    <h3 class="pt-2 subtitle-2" :class="article.color + '--text'" v-if="article.illustration">{{ article.title }}</h3>
  </v-container>
</template>

<script>
export default {
  props: {
    article: Object,
  },
}
</script>

<style lang="scss">
.v-card__actions {
  height: 200px;
}
</style>
