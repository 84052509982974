<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader
        tile
        class="pb-10 skeleton_headercategory"
        type="image"
        height="283"
      ></v-skeleton-loader>
    </div>
    <div v-if="!loading">
      <v-card tile class="py-0 test-color"
        ><v-container>
          <div
            class="triangle corner_header mb-0 font-weight-black white--text home"
          >
            #
          </div>
          <v-row class="align-center pt-12 pb-4">
            <v-text-field
              color="black"
              v-model="query"
              solo
              flat
              dense
              append-icon="mdi-magnify"
              type="text"
              @keyup.enter="sendMessage"
              style="border-radius: 0px; position:absolute; top:0; right: 0;"
            ></v-text-field>

            <v-col cols="12" :sm="6">
              <v-list-item class="py-0">
                <v-list-item-content>
                  <h1 class="py-0 white--text">{{ tag.name }}</h1>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" :sm="6">
              <div class="d-flex align-baseline">
                <span class="white--text font-weight-bold display-1 bigNumber">
                  {{ tag.articles.length }}
                </span>
                <span class="white--text pl-3 body-1 font-weight-medium">
                  Fiches
                </span>
              </div>
            </v-col>
          </v-row>
        </v-container></v-card
      >
    </div>

    <div v-if="!loadingCategory">
      <v-container>
        <v-row>
          <v-col
            cols="6"
            :sm="4"
            :md="3"
            :xl="2"
            v-for="(article, i) in tag.articles"
            :key="article.id + i"
            :article="article"
          >
            <ArticleTeaser :article="article" />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div v-if="loadingCategory">
      <v-container v-for="i in 8" :key="i" class="mb-10">
        <v-row>
          <v-col cols="6" :sm="4" :md="3" :xl="2" class="pb-0">
            <v-skeleton-loader class="pb-3" type="text"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" :sm="4" :md="3" :xl="2" v-for="i in 8" :key="i">
            <v-skeleton-loader class="" type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-divider></v-divider>

    <v-container>
      <v-row class="px-5">
        <v-col cols="9" class="pb-0">
          <v-subheader
            class="subtitle-1 font-weight-medium grey--text pa-0"
            v-html="$t('article.main_categories')"
          ></v-subheader>
        </v-col>
      </v-row>
      <v-row v-if="!loadingCategory" class="px-5">
        <v-col
          cols="6"
          :sm="4"
          :md="3"
          :xl="2"
          v-for="category in categories"
          :key="category.id"
        >
          <CategoryTeaserSelected
            :category="category"
            v-if="category.id === id"
          />
          <CategoryTeaser :category="category" v-if="category.id !== id" />
        </v-col>
      </v-row>
      <v-row v-if="loadingCategory">
        <v-col cols="6" :sm="4" :md="3" :xl="2" v-for="i in 8" :key="i">
          <v-skeleton-loader
            class="ma-0 pa-0 skeleton_categoryteaser"
            type="image"
            height="174"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CategoryTeaser from '@/components/CategoryTeaser'
import ArticleTeaser from '@/components/ArticleTeaser'
import CategoryTeaserSelected from '@/components/CategoryTeaserSelected'

import api from '@/services/api'

export default {
  props: ['id'],
  data() {
    return {
      category: Object,
      categories: [],
      loading: true,
      tag: null,
    }
  },
  created() {
    api
      .getMainCategories(this.$store.getters.getProfile.uid)
      .then((response) => {
        this.categories = response.data
      })
    api.getTag(this.id).then((response) => {
      this.tag = response.data
      this.loading = false
    })
  },

  methods: {
    sendMessage() {
      this.$router.push('/search?query=' + this.query)
    },
  },

  computed: {},
  components: {
    CategoryTeaser,
    CategoryTeaserSelected,
    ArticleTeaser,
  },
}
</script>
<style lang="scss">
@import 'src/assets/css/progressbar.scss';

.triangle {
  &.corner_header {
    position: absolute;
    top: 7px;
    left: 15px;
    z-index: 1;
    font-size: 24px;
    font-family: 'Lora', serif !important;

    &:before {
      content: '';
      position: absolute;
      top: -7px;
      left: -15px;
      z-index: -1;
      border-style: solid;
      border-width: 80px 80px 0 0;
    }
  }
}

.skeleton_headercategory {
  .v-skeleton-loader__image {
    height: 283px;
  }
}

.skeleton_search {
  border-radius: 50px;

  .v-skeleton-loader__card .v-skeleton-loader__image {
    border-radius: 50px;
  }
  .v-skeleton-loader__image {
    height: 48px;
  }
}
.search-container {
  border-radius: 50px;
}
</style>
