import VueI18n from 'vue-i18n'
import Vue from 'vue'
import fr from '@/locales/fr.json'
import de from '@/locales/de.json'
import store from '../store'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: fr,
  messages: { fr, de }, // set locale messages
})
