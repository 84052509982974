import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        competenceClinique: '#96A0BE',
        traumatologie: '#E61400',
        cycleVie: '#C3C850',
        troublesGeneraux: '#4191B4',
        santeSociete: '#FAD200',
        maladiesPsychiques: '#FF9600',
        laboratoire: '#A05087',
        maladieChroniques: '#3C9646',
        dossiersPedago: '#555555',
        toolbox: '#8c8c8c',
        formationEnseignement: '#8c8c8c'
      }
    }
  }
})
