<template>
  <v-card tile class="py-0 test-color"
    ><v-container>
      <div
        class="triangle corner_header mb-0 font-weight-black white--text home"
      ></div>

      <v-row class="align-center pt-9 pt-md-12 pb-4">
        <v-col cols="6" :sm="6" :md="3" class="py-0">
          <div class="d-flex flex-no-wrap align-center">
            <v-avatar color="grey darken-3" size="60">
              <span class="white--text headline">{{
                $store.getters.getProfile.initials
              }}</span>
            </v-avatar>
            <div class="pl-5 white--text">
              <p
                class="ma-0 text-right"
                v-text="
                  $store.getters.getProfile.firstname +
                    ' ' +
                    $store.getters.getProfile.lastname
                "
              ></p>
              <p class="ma-0 text-right"></p>
            </div>
          </div>
        </v-col>
        <v-col cols="6" :sm="6" :md="3" class="py-0">
          <div class="d-flex align-baseline">
            <span class="white--text font-weight-bold display-1 bigNumber">
              {{ totalarticle }}
            </span>
            <span class="white--text pl-3 body-1 font-weight-medium">
              {{ $tc('general.card_number', totalarticle) }}
            </span>
          </div>
        </v-col>
        <v-col cols="12" :sm="12" :md="6" class="py-0 mt-5 mt-md-0">
          <v-card-subtitle
            class="pa-0 pb-2 white--text"
            v-html="$t('general.main_progress')"
          >
          </v-card-subtitle>
          <v-card-text class="pa-0">
            <v-progress-linear
              class="progressbar_custom articlewhite"
              background-color="white"
              color="#616161"
              rounded
              height="45"
              :value="score.score"
            >
              <template v-slot="{ value }">
                <strong class="grey--text text--darken-2"
                  >{{ Math.ceil(value) }}<span class="percent">%</span></strong
                >
              </template>
            </v-progress-linear>
          </v-card-text>
        </v-col>
      </v-row>

      <v-text-field
        v-model="query"
        solo
        flat
        dense
        append-icon="mdi-magnify"
        type="text"
        @keyup.enter="sendMessage"
        class="searchbar"
        label="Recherche"
      ></v-text-field>
    </v-container>
  </v-card>
</template>

<script>
import api from '@/services/api'

export default {
  data() {
    return {
      profile: Object,
      score: 0,
      totalarticle: 0,
      query: '',
    }
  },
  created() {
    api.getScore(this.$store.getters.getProfile.uid).then((response) => {
      this.score = response.data
    })
    api.getTotalArticles().then((response) => {
      this.totalarticle = response.data
    })
  },

  methods: {
    sendMessage() {
      this.$router.push({ name: 'search', params: { query: this.query } })
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/css/progressbar.scss';

.triangle {
  .corner_header {
    position: absolute;
    top: 7px;
    left: 15px;
    z-index: 1;
    font-size: 20px;
    font-family: 'Lora', serif !important;

    &:before {
      content: '';
      position: absolute;
      top: -7px;
      left: -15px;
      z-index: -1;
      border-style: solid;
      border-width: 80px 80px 0 0;
      border-color: rgb(82, 82, 82) transparent transparent transparent;
    }
  }
}

.test-color {
  position: relative;
  background-size: 2000% 2000% !important;
  color: #fff;
  -webkit-animation: GradientAnimation 140s ease infinite;
  -moz-animation: GradientAnimation 140s ease infinite;
  animation: GradientAnimation 140s ease infinite;
  width: auto;
  margin: 0;
  border-top: 0px solid transparent !important;
  background: linear-gradient(
    270deg,
    #4191b4,
    #3c9646,
    #ff9600,
    #e61400,
    #c3c850,
    #fad200,
    #96a0be,
    #a05087
  );
}
@-webkit-keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.searchbar {
  position: absolute;
  top: 10px;
  right: 15px;
}
</style>
