<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card style="padding:0 30px;">
      <v-card-text>
        <h1 class="mt-10">
          Tectum – La plateforme de soutien à l’enseignement développée par
          l’Institut de Médecine de Famille de l’Université de Fribourg
        </h1>

        <p class="mt-2">
          A disposition des étudiant·e·s et des enseignant·e·s en cabinet, en
          deux langues, cette plateforme s’utilise à différents niveaux :
        </p>
        <h2 class="mt-7">
          1. Définir les champs d’apprentissage de la médecine de famille durant
          les stages en cabinet
        </h2>
        <p class="mt-2">
          <strong>Tectum</strong> permet de passer d’une formation au hasard des
          consultations à une formation pro-active, centrée sur les besoins
          d’apprentissage. Une large palette de problèmes de santé y sont
          abordés, permettant de parcourir les différents champs de la médecine
          de famille. Pour les étudiant·e·s, cet aspect correspond aux objectifs
          des SSP (situations as starting point) du Profiles.
        </p>
        <h2 class="mt-7">2. Des objectifs d’apprentissage spécifiques</h2>
        <p class="mt-2">
          Chaque article est introduit par des objectifs d’apprentissage
          spécifiques qui permettront d’être vigilant·e aux aspects importants
          tels que les drapeaux rouges, les éléments-clés de l’anamnèse ou le
          diagnostic différentiel.
        </p>
        <h2 class="mt-7">3. Des supports de formation à enrichir</h2>
        <p class="mt-2">
          Dans Tectum, chaque article comprend un support de formation
          spécifique et/ou des articles jugés pertinents pour lapratique en
          cabinet de médecine de famille. Grâce à votre contribution comme
          utilisateurs·trices, le contenu de chaque thématique pourra être
          enrichi. N’hésitez pas à nous transmettre les articles que vous
          estimez importants (avec accès autorisé) pour faire évoluer Tectum.
        </p>
        <p class="mt-5">
          <i
            >La richesse de Tectum réside dans la participation de toute la
            communauté d’enseignant·e·s et d’étudiant·e·s pour contribuer à son
            amélioration. Ensemble, nous allons co-créer et faire évoluer cet
            outil dynamique et pratique pour la médecine de tous les jours.</i
          >
        </p>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0 d-flex justify-center actions">
        <v-btn class="ma-2" @click.prevent="close()">
          Ok
          <v-icon right dark>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: 0,
    }
  },
  methods: {
    close() {
      this.$store.dispatch('SET_DIALOG')
      this.dialog = 0
    },
  },
  created() {
    this.dialog = this.$store.getters.getDialog
  },
  components: {},
}
</script>
<style lang="scss">
h1 {
  line-height: 30px;
}
h2 {
  line-height: 25px;
}
.actions {
  height: 100px !important;
}
</style>
