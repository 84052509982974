<template>
	<v-img
		src="@/assets/img/trame_tectum_square.png"
		height="100%"
		cover
		class="d-flex justify-center align-center"
	>
		<v-container class="d-flex justify-center align-center">
			<v-card class="mx-auto pa-5" width="500" outlined>
				<v-img
					src="@/assets/img/tectum.png"
					width="150px"
					class="ma-4 mb-5"
				>
				</v-img>

				<v-card-text>
					<v-form ref="form">
						<v-text-field
							v-model="email"
							label="E-mail"
							required
						></v-text-field>

						<v-btn
							@click="login"
							color="grey darken-2"
							class="mt-5 white--text"
							:loading="loading"
							>Envoyer - Senden</v-btn
						>
					</v-form>
					<v-row class="mt-6 justify-center">
						<router-link
							:to="{ name: 'login' }"
							style="color: #666"
						>
							Login
						</router-link>
					</v-row>
				</v-card-text>
			</v-card>
		</v-container>
	</v-img>
</template>

<script>
import { AUTH_REQUEST } from "@/store/actions/auth";

export default {
	data() {
		return {
			show1: false,
			username: "dumont@bluesystem.ch",
			password: "12345677",
			loading: false,
		};
	},
	methods: {
		login: function () {
			this.loading = true;
			const { username, password } = this;

			this.$store
				.dispatch(AUTH_REQUEST, { username, password })
				.then(() => {
					this.loading = false;
					this.$router.push({ name: "home" });
				})
				.catch(() => {
					this.loading = false;
				});
		},
	},
};
</script>
