<template>
  <v-card
    class="d-inline-block"
    link
    width="100%"
    :to="'/' + $i18n.locale + '/category/' + category.id"
    :key="'/' + $i18n.locale + '/category/' + category.id"
  >
    <v-container class="pa-2">
      <div
        class="triangle corner_appnavigationcategory mb-0 font-weight-black white--text"
        :class="category.color + '_color'"
      >
        {{ category.num }}
      </div>
      <v-row>
        <v-col cols="10" class="py-0 pl-8 pr-0 d-flex align-center">
          <p :class="'mb-0 ' + category.color + '--text' + ' subtitle-2'">
            {{ category.name }}
          </p>
        </v-col>

        <v-col cols="2" align="center" class="py-0">
          <v-list-item class="pa-0">
            <v-list-item-content>
              <v-icon x-small :color="category.color">
                mdi-arrow-right
              </v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    category: Object,
  },
}
</script>

<style lang="scss">
.triangle {
  &.corner_appnavigationcategory {
    position: absolute;
    top: 4px;
    left: 7px;
    z-index: 1;
    font-size: 10px;
    font-family: 'Lora', serif !important;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: -7px;
      z-index: -1;
      border-style: solid;
      border-width: 35px 35px 0 0;
    }
  }
}
</style>
