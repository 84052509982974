<template>
  <div v-if="$route.name != 'home'">
    <div v-if="!loading">
      <v-list-item
        class="my-2 px-2"
        v-for="category in categories"
        :key="category.id"
      >
        <AppNavigationCategorySelected v-if="category.complementary === '0'" :category="category" />
      </v-list-item>
    </div>
    <div v-if="loading">
      <v-skeleton-loader
        class="my-2 px-2 skeleton_appnavigation"
        type="card"
        height="55"
        v-for="i in 8"
        :key="i"
      ></v-skeleton-loader>
    </div>
    <v-divider></v-divider>
  </div>
</template>

<script>
import AppNavigationCategorySelected from '@/components/AppNavigationCategorySelected'
import api from '@/services/api'

export default {
  data() {
    return {
      categories: Array,
      loading: false,
      profile: this.$store.getters.getProfile,
    }
  },
  mounted() {
    api.getMainCategories(this.profile.uid).then((response) => {
      this.categories = response.data
    })
  },
  components: {
    AppNavigationCategorySelected,
  },
}
</script>
