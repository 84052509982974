<template>
  <v-container class="px-0 py-0">
    <div class="secondary-card">
        <v-card
      :to="{ name: 'article', params: { id: article.id } }"
      class="ma-xs-12 is-secondary"
    >
      <v-responsive :aspect-ratio="1" v-if="article.illustration">
        <v-img
          :src="article.illustration"
          class="d-flex align-end img-secondary"
          aspect-ratio="1"
          contain
        >
          <v-card-actions
            class="flex-column justify-end align-end"
            min-width="100%"
            aspect-ratio="1">
          </v-card-actions>
        </v-img>
      </v-responsive>
    </v-card>
    </div>

    <h3 class="pt-2 subtitle-2" :class="article.color + '--text'">{{ article.title }}</h3>
  </v-container>
</template>

<script>
export default {
  props: {
    article: Object,
  },
}
</script>

<style lang="scss">
.v-card__actions {
  height: 200px;
  max-height: 184px;
}

.img-secondary {
  /*transform: scale(0.8);*/
  border: 1px solid black;
}

.secondary-card {
    background: darken(#fff, 10%);
}

.is-secondary {
    transform: scale(0.7);
}
</style>
