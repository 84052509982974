<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-subheader class="title font-weight-medium px-5 py-0">
          {{ subcategory.name }}
        </v-subheader>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        :sm="4"
        :md="3"
        :lg="2"
        :xl="2"
        v-for="(article, i) in subcategory.articles"
        :key="article.id + i"
        :article="article"
      >
        <ArticleTeaser :article="article" />
      </v-col>
      <v-col
        cols="6"
        :sm="4"
        :md="3"
        :lg="2"
        :xl="2"
        v-for="(article, i) in subcategory.linked_articles"
        :key="article.id + i"
        :article="article"
      >
        <LinkedArticleTeaser :article="article" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArticleTeaser from '@/components/ArticleTeaser'
import LinkedArticleTeaser from '@/components/LinkedArticleTeaser'

export default {
  props: {
    subcategory: Object,
  },
  components: {
    ArticleTeaser,
    LinkedArticleTeaser
  },
}
</script>
