import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { i18n } from './plugins/i18n'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueAnalytics from 'vue-analytics'

Vue.use(VueYouTubeEmbed)
Vue.use(VueAnalytics, {
  id: 'UA-220280350-2',
  router,
})

//Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.config.productionTip = false

new Vue({
  iconfont: 'mdi',
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
