<template>
	<div>
		<v-card tile outlined class="login-header">
			<v-row>
				<v-col>
					<v-img
						src="@/assets/img/tectum.png"
						class="
							ma-5
							mb-4
							ma-sm-7
							mb-sm-6
							ma-md-12
							mb-md-11
							login-logo
						"
					>
					</v-img>
				</v-col>
				<v-col align-self="center">
					<div class="d-flex justify-end mr-3">
						<p
							class="title mr-6 mt-4 lang"
							:style="
								user.lang == 'fr'
									? 'opacity: 100%;'
									: 'opacity: 60%;'
							"
							@click="setLanguage('fr')"
						>
							FR
						</p>
						<v-divider vertical></v-divider>
						<p
							class="title ml-6 mt-4 lang"
							:style="
								user.lang == 'de'
									? 'opacity: 100%;'
									: 'opacity: 60%;'
							"
							@click="setLanguage('de')"
						>
							DE
						</p>
					</div>
				</v-col>
			</v-row>
		</v-card>

		<v-img
			src="@/assets/img/fond_login3.jpg"
			cover
			class="d-flex justify-center align-center bg-img-login"
		>
			<v-container class="container-login">
				<v-row>
					<v-col cols="12" md="7">
						<v-list-item class="pa-0 mb-4">
							<v-list-item-content>
								<h1
									class="
										mb-4
										text-h5 text-sm-h4
										font-weight-bold
										white--text
									"
									v-html="$t('pages.login.page_title')"
								></h1>
								<p
									class="title mb-0 white--text"
									v-html="$t('pages.login.page_intro')"
								></p>
							</v-list-item-content>
						</v-list-item>
						<v-btn
							color="white"
							@click="login"
							v-html="$t('profil.login')"
						></v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-img>
	</div>
</template>

<script>
import { AUTH_REQUEST } from "@/store/actions/auth";
import { mapState } from "vuex";

export default {
	data() {
		if (
			location.hostname === "localhost" ||
			location.hostname === "127.0.0.1"
		) {
			var login_url =
				"https://www.frimed.ch/external_login/checkuser?redirect_uri=http://localhost:8080";
		} else {
			var login_url =
				"https://www.frimed.ch/external_login/checkuser?redirect_uri=https://www.tectumed.ch";
		}
		return {
			popup: null,
			login_url: login_url,
		};
	},
	computed: mapState(["user"]),
	methods: {
		login: function () {
			var height = 500;
			var width = 400;
			var left = screen.width / 2 - width / 2;
			var top = screen.height / 2 - height / 2;
			var options = "";
			options += ",width=" + width;
			options += ",height=" + height;
			options += ",top=" + top;
			options += ",left=" + left;
			this.popup = window.open(this.login_url, "Login Frimed", options);
			window.addEventListener("message", this.receiveMessage, false);
		},
		receiveMessage(event) {
			if (event.origin !== "https://www.frimed.ch") return;

			if (event.data.email) {
				this.$store
					.dispatch(AUTH_REQUEST, event.data)
					.then((resp) => {
						this.popup.postMessage(
							"loginok",
							"https://www.frimed.ch"
						);
						this.loading = false;
						if (resp) {
							this.$router.push({ name: "home" });
						} else {
							this.$router.push({ name: "login" });
						}
					})
					.catch(() => {
						this.loading = false;
					});
			}
		},
		setLanguage(language) {
			this.$store.dispatch("SET_LANGUAGE", language);
			this.$router.push({
				name: this.$route.name,
				params: { lang: language },
			}),
				this.$router.go();
		},
	},
	mounted() {
		//localStorage.clear();
	},
};
</script>
<style lang="scss">
.bg-img-login .v-image__image--cover {
	// filter: blur(4px) brightness(75%);
	filter: brightness(65%);
}

.lang {
	cursor: pointer;
}

@media screen and (min-width: 960px) {
	.login-logo {
		width: 350px !important;
	}
}

.login-header {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 100;
}
.bg-img-login {
	max-height: 100vh;
	height: 100vh;
}

@media screen and (min-width: 600px) and (max-width: 959px) {
	.login-logo {
		width: 250px !important;
	}
}
@media screen and (max-width: 599px) {
	.login-logo {
		width: 150px !important;
	}

	.bg-img-login {
		max-height: 100vh;
		overflow-y: scroll;
		height: 100vh;
		// position: fixed;
	}

	.container-login {
		padding-top: 80px;

		.v-list-item__content h1 {
			font-size: 22px !important;
			line-height: 28px;
		}

		.v-list-item__content p {
			font-size: 17px !important;
			line-height: 22px;
		}
	}

	.v-footer {
		position: initial;
	}
}

@media screen and (min-width: 600px) and (max-height: 850px) {
	.login-logo {
		width: 220px !important;
		margin: 20px !important;
	}

	.v-application .ma-md-12.mb-md-11 {
		margin: 40px !important;
	}

	.login-header {
		position: relative;
	}

	.v-footer--absolute {
		position: initial;
	}

	.bg-img-login {
		max-height: calc(100vh - 140px);
		height: calc(100vh - 140px);
	}
}
</style>
