import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  SET_LANGUAGE,
  SET_DIALOG,
} from '../actions/user'
import ApiCall from '@/utils/api'
import Vue from 'vue'
import { AUTH_LOGOUT } from '../actions/auth'
import { i18n } from '../../plugins/i18n'

const state = {
  status: 'success',
  readInfo: localStorage.getItem('readInfo'),
  profile: JSON.parse(localStorage.getItem('profile')),
  lang: localStorage.getItem('lang') == 'de' ? 'de' : 'fr',
}

const getters = {
  getProfile: (state) => state.profile,
  getDialog: (state) => {
    console.log(state.readInfo)
    if (state.readInfo == '1') {
      return false
    } else {
      return true
    }
  },
  getLanguage: (state) => state.lang,
  isProfileLoaded: (state) => !!state.profile.name,
}

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(USER_REQUEST)
      ApiCall({
        url: '/data/api/get_user',
        method: 'post',
        formData: user,
      })
        .then((resp) => {
          let profile = {
            name: resp.data.name.split('@')[0],
            uid: resp.data.uid,
            email: resp.data.name,
            initials: resp.data.initials,
            firstname: resp.data.firstname,
            lastname: resp.data.lastname,
            lang: resp.data.language == 'de' ? 'de' : 'fr',
          }

          localStorage.setItem('profile', JSON.stringify(profile))
          localStorage.setItem('lang', profile.lang)
          commit(USER_SUCCESS, profile)
          commit(USER_SUCCESS, profile)

          resolve(resp)
        })
        .catch((err) => {
          commit(USER_ERROR)
          dispatch(AUTH_LOGOUT)
          reject(err)
        })
    })
  },
  [SET_LANGUAGE]: ({ commit }, language) => {
    localStorage.setItem('lang', language)
    i18n.locale = language
    commit(SET_LANGUAGE, language)
  },
  [SET_DIALOG]: ({ commit }) => {
    localStorage.setItem('readInfo', '1')
    commit(SET_DIALOG, '1')
  },
}

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success'
    Vue.set(state, 'profile', resp)
  },
  [USER_ERROR]: (state) => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {}
    localStorage.clear()
  },
  [SET_LANGUAGE]: (state, language) => {
    state.lang = language
  },
  [SET_DIALOG]: (state, key = '1') => {
    state.readInfo = key
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
