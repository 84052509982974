<template>
  <div>
    <v-card tile outlined class="py-10 test-color">
      <v-container>
        <v-row class="px-5">
          <v-col cols="12" :sm="6">
            <h1 class="pb-5 white--text">
              {{ $t('profil.profile_header') }}
            </h1>
          </v-col>
          <v-col cols="6">
            <div class="d-flex flex-no-wrap justify-end align-center">
              <div class="pr-5 white--text">
                <p
                  class="ma-0 text-right"
                  v-text="
                    $store.getters.getProfile.firstname +
                      ' ' +
                      $store.getters.getProfile.lastname
                  "
                ></p>
                <p class="ma-0 text-right"></p>
              </div>
              <v-avatar color="grey darken-3" size="60">
                <span class="white--text headline">{{
                  $store.getters.getProfile.initials
                }}</span>
              </v-avatar>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container>
      <v-row class="px-5">
        <v-col cols="12" class="pb-0">
          <v-subheader
            class="subtitle-1 font-weight-medium grey--text pa-0"
            v-html="$t('article.main_categories')"
          ></v-subheader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProfileHeader from '@/components/ProfileHeader'
import api from '@/services/api'

export default {
  name: 'home',
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    this.loading = false
  },
  components: {
    ProfileHeader,
  },
}
</script>
<style lang="scss">
.skeleton_homesmallteaser {
  .v-skeleton-loader__card .v-skeleton-loader__image {
    border-radius: 5px;
  }
  .v-skeleton-loader__image {
    height: 86px;
  }
}
.skeleton_categoryteaser {
  .v-skeleton-loader__card .v-skeleton-loader__image {
    border-radius: 5px;
  }
  .v-skeleton-loader__image {
    height: 174px;
  }
}
.skeleton_search {
  border-radius: 50px;

  .v-skeleton-loader__card .v-skeleton-loader__image {
    border-radius: 50px;
  }
  .v-skeleton-loader__image {
    height: 48px;
  }
}
.search-container {
  border-radius: 50px;
}
</style>
