var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"to":{ name: 'category', params: { id: _vm.category.id } }}},[_c('v-container',{staticClass:"py-4 px-0"},[_c('div',{staticClass:"triangle corner_articleteaser mb-0 font-weight-black white--text",class:_vm.category.color + '_color'},[_vm._v(" "+_vm._s(_vm.category.num)+" ")]),_c('v-row',[_c('v-col',{staticClass:"pa-0 pl-3",attrs:{"cols":"9"}},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"py-0 align-end",staticStyle:{"height":"120px"}},[_c('p',{class:_vm.category.color +
                  '--text' +
                  ' mb-0' +
                  ' ml-5' +
                  ' align-self-end' +
                  ' subtitle-2' +
                  ' d-inline-block' +
                  ' text-truncate',staticStyle:{"max-width":"calc(100% - 20px)"}},[_vm._v(" "+_vm._s(_vm.category.name)+" ")])])],1)],1),_c('v-col',{staticClass:"pa-0 align-self-end",attrs:{"cols":"2"}},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"pb-0 mr-5"},[_c('v-icon',{attrs:{"medium":"","color":_vm.category.color}},[_vm._v(" mdi-arrow-right ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }