<template>
	<v-layout
		class="pa-0"
		column
		justify-space-between
		style="width: 100% Important; height: 100%"
	>
		<div>
			<router-link
				:to="{ name: 'home', params: { lang: this.$i18n.locale } }"
				:key="$route.fullPath"
			>
				<v-img
					src="@/assets/img/tectum.png"
					contain
					class="mr-5 ml-4 my-5"
				></v-img>
			</router-link>

			<v-divider></v-divider>
			<AppNavigationCategories v-if="renderComponent" />
		</div>

		<div v-if="$store.getters.getProfile">
			<v-divider></v-divider>

			<v-list-item link :to="{ name: 'profile' }">
				<v-list-item-content>
					<v-list-item-title
						v-text="
							$store.getters.getProfile.firstname +
							' ' +
							$store.getters.getProfile.lastname
						"
					></v-list-item-title>
				</v-list-item-content>
				<v-list-item-icon>
					<v-avatar color="grey darken-2" size="36">
						<span class="white--text subtitle-1">
							{{ $store.getters.getProfile.initials }}
						</span>
					</v-avatar>
				</v-list-item-icon>
			</v-list-item>

			<v-divider></v-divider>
			<v-list-item link @click="logout">
				<v-list-item-icon>
					<v-icon>mdi-key</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title
						v-html="$t('profil.logout')"
					></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</div>
		<v-spacer></v-spacer>
		<div class="d-flex justify-center">
			<p
				class="title mr-6 mt-4 lang"
				:style="user.lang == 'fr' ? 'opacity: 100%;' : 'opacity: 60%;'"
				@click="setLanguage('fr')"
			>
				FR
			</p>
			<v-divider vertical></v-divider>
			<p
				class="title ml-6 mt-4 lang"
				:style="user.lang == 'de' ? 'opacity: 100%;' : 'opacity: 60%;'"
				@click="setLanguage('de')"
			>
				DE
			</p>
		</div>
	</v-layout>
</template>

<script>
import AppNavigationCategories from "@/components/AppNavigationCategories";

import { AUTH_LOGOUT } from "@/store/actions/auth";
// import { Trans } from '@/plugins/Translation'

import { mapState } from "vuex";

import api from "@/services/api";

export default {
	props: ["id"],
	data() {
		return {
			categories: [],
			loading: true,
			renderComponent: false,
		};
	},
	computed: {
		...mapState(["user"]),
	},
	methods: {
		logout: function () {
			this.$store
				.dispatch(AUTH_LOGOUT)
				.then(() => this.$router.push({ name: "login" }));
		},
		profile: function () {
			this.$store
				.dispatch(AUTH_LOGOUT)
				.then(() => this.$router.push({ name: "profile" }));
		},
		isCurrentLanguage(lang) {
			return lang === this.currentLanguage;
		},
		setLanguage(language) {
			this.$store.dispatch("SET_LANGUAGE", language);
			this.$router.push({
				name: this.$route.name,
				params: { lang: language },
			});
			this.$router.go();
		},
	},

	mounted() {
		if (this.$route.name != "login") {
			this.renderComponent = true;
		}
	},
	watch: {
		$route(to, from) {
			if (this.$route.name != "login") {
				this.renderComponent = true;
			}
		},
	},
	components: {
		AppNavigationCategories,
	},
};
</script>
<style lang="scss">
.lang {
	cursor: pointer;
}

.skeleton_appnavigation {
	.v-skeleton-loader__card .v-skeleton-loader__image {
		border-radius: 5px;
	}
	.v-skeleton-loader__image {
		height: 55px;
	}
}
</style>
