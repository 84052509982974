import ApiCall from '@/utils/api'
import ApiNoCacheCall from '@/utils/apinocache'
import { i18n } from '../plugins/i18n'
const v = 1

export default {
  getCurrentUser(uid) {
    return new Promise((resolve, reject) => {
      let data = localStorage.getItem(v + '_current_user' + uid)

      if (data) {
        resolve(JSON.parse(data))
        return
      }
      ApiCall({ url: '/rest/api/user?_format=json', method: 'get' })
        .then((response) => {
          localStorage.setItem(
            v + '_current_user' + uid,
            JSON.stringify(response)
          )
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getArticlesByCategory(category) {
    const lang = i18n.locale
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/api/articlesbycategory/' + lang + '/' + category,
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getMainCategories(user) {
    const lang = i18n.locale
    return new Promise((resolve, reject) => {
      ApiCall({ url: '/api/categories/' + lang + '/' + user, method: 'get' })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getObjectives(article, user) {
    const lang = i18n.locale
    return new Promise((resolve, reject) => {
      ApiNoCacheCall({
        url: '/api/getobjectives/' + lang + '/' + article + '/' + user,
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTotalArticles() {
    return new Promise((resolve, reject) => {
      ApiCall({ url: '/api/totalarticles', method: 'get' })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSubCategories(parent) {
    return new Promise((resolve, reject) => {
      let data = localStorage.getItem(v + '_subcategory_' + parent)

      if (data) {
        resolve(JSON.parse(data))
        return
      }
      ApiCall({ url: '/api/subcategories/' + parent, method: 'get' })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getLatestArticles(user) {
    const lang = i18n.locale
    return new Promise((resolve, reject) => {
      ApiNoCacheCall({
        url: '/api/user/getlatest/' + lang + '/' + user,
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setLatestArticles(user, article) {
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/api/user/setlatest/' + user + '/' + article,
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getMainCategory(category, user) {
    const lang = i18n.locale
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/api/category/' + lang + '/' + category + '/' + user,
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getScore(user) {
    return new Promise((resolve, reject) => {
      ApiNoCacheCall({ url: '/api/user/getscore/' + user, method: 'get' })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getCategory(category) {
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/taxonomy/term/' + category + '?_format=json',
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getTag(id) {
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/api/tag/' + id,
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getSearch(query) {
    const lang = i18n.locale
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/api/search/' + lang + '?query=' + query,
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getArticle(article, user) {
    const lang = i18n.locale
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/api/article/' + lang + '/' + article + '/' + user,
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  switchObjectif(article, obj, user) {
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/api/user/switchobj/' + article + '/' + obj + '/' + user,
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  switchFavorite(article, user) {
    return new Promise((resolve, reject) => {
      ApiCall({
        url: '/api/user/switchfavorrite/' + article + '/' + user,
        method: 'get',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getComplementaryCategories(user) {
    const lang = i18n.locale
    return new Promise((resolve, reject) => {
      ApiCall({ url: '/api/get-complementary-categories/' + lang + '/' + user, method: 'get' })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
