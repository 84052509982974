import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import Article from '../views/Article.vue'
import Category from '../views/Category.vue'
import Tag from '../views/Tag.vue'
import Search from '../views/Search.vue'
import Login from '../views/Login.vue'
import Reset from '../views/Reset.vue'
import store from '../store'
import { i18n } from '../plugins/i18n'

Vue.use(VueRouter)

let lang = store.getters.getLanguage

const ifAuthenticated = (from, to, next) => {
  if (store.getters.isAuthenticated === true) {
    next()
    return
  }
  next({ name: 'login', params: { lang: lang } })
}

const routes = [
  {
    path: '/',
    redirect: { name: 'home' },
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/:lang',
    name: 'home',
    component: Home,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/:lang/profile',
    name: 'profile',
    component: Profile,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/:lang/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/:lang/article/:id',
    name: 'article',
    props: true,
    component: Article,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: '/:lang/category/:id',
    name: 'category',
    props: true,
    component: Category,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: '/:lang/tag/:id',
    name: 'tag',
    component: Tag,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: '/:lang/search',
    name: 'search',
    component: Search,
    beforeEnter: ifAuthenticated,
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.beforeResolve((to, from, next) => {
  lang = store.getters.getLanguage
  i18n.locale = store.getters.getLanguage
  next()
})

export default router
