<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader
        tile
        class="pb-10 skeleton_headercategory"
        type="image"
        height="283"
      ></v-skeleton-loader>
    </div>
    <div v-if="!loading">
      <v-card tile outlined class="py-0 test-color"
        ><v-container>
          <v-row class="align-center pt-12 pb-4">
            <v-text-field
              color="black"
              v-model="queryNew"
              solo
              flat
              dense
              append-icon="mdi-magnify"
              type="text"
              @keyup.enter="reSearch"
              style="border-radius: 0px; position:absolute; top:0; right: 0;"
            ></v-text-field>

            <v-col cols="12" :sm="6">
              <v-list-item class="py-0">
                <v-list-item-content>
                  <h1 class="py-0 white--text">Résultats de recherche</h1>
                </v-list-item-content>
              </v-list-item>
            </v-col>

            <v-col cols="12" :sm="6">
              <v-row v-if="articles.length">
                <v-col cols="6">
                  <div class="d-flex align-baseline">
                    <span
                      class="white--text font-weight-bold display-1 bigNumber"
                    >
                      {{ articles.length }}
                    </span>
                    <span class="white--text pl-3 body-1 font-weight-medium">
                      {{ $tc('general.card_number', articles.length) }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>

    <div v-if="!loading">
      <v-container>
        <v-row v-if="articles.length">
          <v-col
            cols="6"
            :sm="4"
            :md="3"
            :xl="2"
            v-for="(article, i) in articles"
            :key="article.id + i"
            :article="article"
          >
            <ArticleTeaser :article="article" />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-subheader class="subtitle-1 font-weight-medium pa-8"
            >La recherche n'a donné aucun résultat</v-subheader
          >
        </v-row>
      </v-container>
    </div>

    <div v-if="loading">
      <v-container v-for="i in 8" :key="i" class="mb-10">
        <v-row>
          <v-col cols="6" :sm="4" :md="3" :xl="2" class="pb-0">
            <v-skeleton-loader class="pb-3" type="text"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" :sm="4" :md="3" :xl="2" v-for="i in 8" :key="i">
            <v-skeleton-loader class="" type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-divider></v-divider>

    <v-container>
      <v-row class="px-5">
        <v-col cols="9" class="pb-0">
          <v-subheader
            class="subtitle-1 font-weight-medium grey--text pa-0"
            v-html="$t('article.main_categories')"
          ></v-subheader>
        </v-col>
        <v-col cols="3" class="pb-0">
          <!-- <v-toolbar dense v-if="!loading" class="search-container">
            <v-text-field
              hide-details
              single-line
              prepend-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
          <v-skeleton-loader
            class="ma-0 pa-0 skeleton_search"
            type="image"
            height="48"
            v-if="loading"
          ></v-skeleton-loader> -->
        </v-col>
      </v-row>
      <v-row v-if="!loading" class="px-5">
        <v-col
          cols="6"
          :sm="4"
          :md="3"
          :xl="2"
          v-for="category in categories"
          :key="category.id"
        >
          <CategoryTeaser :category="category" />
        </v-col>
      </v-row>
      <v-row v-if="loading">
        <v-col cols="6" :sm="4" :md="3" :xl="2" v-for="i in 8" :key="i">
          <v-skeleton-loader
            class="ma-0 pa-0 skeleton_categoryteaser"
            type="image"
            height="174"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CategoryTeaser from '@/components/CategoryTeaser'
import ArticleTeaser from '@/components/ArticleTeaser'
import CategoryTeaserSelected from '@/components/CategoryTeaserSelected'

import api from '@/services/api'

export default {
  props: ['query'],
  data() {
    return {
      categories: [],
      articles: null,
      loading: true,
      queryNew: '',
    }
  },

  methods: {
    reSearch() {
      api.getSearch(this.queryNew).then((response) => {
        this.articles = response.data
      })
    },
  },

  created() {
    api
      .getMainCategories(this.$store.getters.getProfile.uid)
      .then((response) => {
        this.categories = response.data
      })
    api.getSearch(this.$route.params.query).then((response) => {
      this.articles = response.data
      this.loading = false
    })
  },
  computed: {},
  components: {
    CategoryTeaser,
    CategoryTeaserSelected,
    ArticleTeaser,
  },
}
</script>
<style lang="scss">
.skeleton_headercategory {
  .v-skeleton-loader__image {
    height: 283px;
  }
}

.skeleton_search {
  border-radius: 50px;

  .v-skeleton-loader__card .v-skeleton-loader__image {
    border-radius: 50px;
  }
  .v-skeleton-loader__image {
    height: 48px;
  }
}
.search-container {
  border-radius: 50px;
}
</style>
