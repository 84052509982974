<template>
  <div>
    <div v-if="!loading">
      <ArticleHeader :article="article" :previous="previous" :next="next" />
    </div>
    <v-skeleton-loader
      class="mx-auto skeleton_header"
      type="image"
      v-if="loading"
      min-height="274"
    >
    </v-skeleton-loader>

    <v-container class="mt-n12" v-if="!loading">
      <div :class="article.category.color + '_color'">
        <v-card tile class="pt-md-6 px-lg-12 pb-12">
          <v-container class="pa-0">
            <v-row class="pt-5 pt-md-10">
              <v-col cols="12" md="7" class="px-10">
                <h1
                  v-html="article.title"
                  :class="article.category.color + '--text text--darken-1'"
                ></h1>
              </v-col>
              <v-col cols="12" md="5" class="px-10 py-0">
                <p
                  class="
										text-left text-md-right
										mb-0 mb-md-10
									"
                >
                  <span class="grey--text body-2">
                    {{ $tc('general.card_number', 1) }}
                  </span>
                  <span
                    class="
											font-weight-bold
											display-1
											pl-4
											bigNumber
										"
                    :class="article.category.color + '--text text--darken-1'"
                  >
                    {{ article.position }}/{{ article.nbarticles }}
                  </span>
                </p>
              </v-col>
            </v-row>

            <v-row class="d-flex flex-column-reverse flex-md-row">
              <v-col cols="12" :md="8" class="px-10 left-col-article">
                <div ref="anchor_mainobj" v-if="objectives.main">
                  <h2 class="mb-2 mt-0" v-html="$t('article.main_obj')"></h2>
                  <ul>
                    <li
                      v-for="(obj, i) in objectives.main"
                      :key="'mainobj' + i"
                      v-html="obj.title"
                      :class="article.category.color + '--bullet darken-1'"
                    ></li>
                  </ul>
                </div>

                <div id="anchor_secondobj" v-if="objectives.secondary">
                  <h2 class="mt-10 mb-2" v-html="$t('article.second_obj')"></h2>
                  <ul>
                    <li
                      v-for="(obj, i) in objectives.secondary"
                      :key="'secobj' + i"
                      v-html="obj.title"
                      :class="article.category.color + '--bullet'"
                    ></li>
                  </ul>
                </div>

                <div ref="anchor_content" v-if="article.body">
                  <h2
                    class="mt-10 mb-2"
                    v-html="$t('article.fiche_content')"
                  ></h2>
                  <div v-html="article.body" class="article-text"></div>
                  <!-- <v-btn :class="article.category.color + '--text'">
                    En savoir plus
                  </v-btn> -->
                </div>

                <div v-if="article.pdf">
                  <h2
                    class="mt-10 mb-0"
                    v-html="$t('article.download_pdf')"
                  ></h2>
                  <v-btn
                    block
                    class="
											btn-article
											white--text
											px-0
											mb-12
											mt-4
										"
                    :class="article.category.color + ' text--darken-1'"
                    :href="article.pdf"
                    target="_blank"
                  >
                    {{ $t('article.download_pdf_action')
                    }}<v-icon right>mdi-file-pdf</v-icon>
                  </v-btn>
                </div>

                <div ref="anchor_doc" v-if="article.annexes">
                  <h2 class="mt-10 mb-4" v-html="$t('article.useful_doc')"></h2>
                  <v-btn
                    v-for="(annexe, i) in article.annexes"
                    :key="'annexe_' + i"
                    class="
											btn-column-article
											d-flex
											py-0
											px-0
											x-large
										"
                    :class="article.category.color + '--text text--darken-1'"
                    :href="annexe.url"
                    target="_blank"
                    x-large
                    style="height: 100%"
                  >
                    <v-container v-if="annexe.type == 'full'" class="pa-0">
                      <v-row class="py-0">
                        <v-col cols="11" class="py-0">
                          <!-- <p class="doc-title">{{ annexe.title }} exemple de titre</p>
                          <p class="doc-descr">
                            {{ annexe.description }}testcedric
                          </p> -->

                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title
                                class="
																	text-body-1
																	font-weight-medium
																	text-wrap
																	mb-2
																"
                                :class="
                                  article.category.color +
                                    '--text text--darken-1'
                                "
                                style="
																	text-transform: none;
																"
                                >{{ annexe.title }}</v-list-item-title
                              >
                              <v-list-item-subtitle
                                class="
																	text-body-2
																"
                                style="
																	text-transform: none;
																"
                                >{{ annexe.description }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col
                          cols="1"
                          class="
														d-flex
														justify-center
														align-center
														pa-0
													"
                        >
                          <v-icon right class="ma-0">mdi-file</v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-else class="pa-0">
                      <v-row class="py-0">
                        <v-col cols="11" class="text-truncate py-0">
                          <v-list-item three-line class="pa-0">
                            <v-list-item-content class="py-0">
                              <v-list-item-title
                                class="
																	text-body-1
																	font-weight-medium
																	text-wrap
																"
                                :class="
                                  article.category.color +
                                    '--text text--darken-1'
                                "
                                style="
																	text-transform: none;
																"
                                >{{ annexe.title }}</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col
                          cols="1"
                          class="
														d-flex
														justify-center
														align-center
														pa-0
													"
                        >
                          <v-icon right class="ma-0">mdi-file</v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-btn>
                </div>

                <div ref="anchor_link" v-if="article.links">
                  <h2
                    class="mt-10 mb-4"
                    v-html="$t('article.useful_link')"
                  ></h2>
                  <v-btn
                    v-for="(link, i) in article.links"
                    :key="'link_' + i"
                    class="
											btn-column-article
											d-flex
											py-3
											px-0
											x-large
										"
                    :class="article.category.color + '--text text--darken-1'"
                    :href="link.url"
                    target="_blank"
                    x-large
                    style="height: 100%"
                  >
                    <v-container class="pa-0">
                      <v-row class="py-0">
                        <v-col cols="11" class="py-0">
                          <v-list-item class="pa-0">
                            <v-list-item-content class="py-0">
                              <v-list-item-title
                                class="
																	text-body-1
																	font-weight-medium
																	text-wrap
																"
                                :class="
                                  article.category.color +
                                    '--text text--darken-1'
                                "
                                style="
																	text-transform: none;
																"
                                >{{ link.title }}</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col
                          cols="1"
                          class="
														d-flex
														justify-center
														align-center
														pa-0
													"
                        >
                          <v-icon left class="ma-0">mdi-link</v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-btn>
                </div>

                <div ref="anchor_video" v-if="article.videos">
                  <h2 class="mt-10 mb-4" v-html="$t('article.video')"></h2>
                  <v-container
                    v-for="(videos, title) in article.videos"
                    :key="'cat_' + title"
                  >
                    <h3 class="mt-15" v-if="title != '-'">
                      {{ title }}
                    </h3>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        xl="6"
                        v-for="(video, j) in videos"
                        :key="'video_' + j"
                      >
                        <p v-if="video.title">
                          {{ video.title }}
                        </p>
                        <div class="videoarticle-responsive">
                          <youtube
                            v-if="video.type == 'youtube'"
                            :video-id="video.id"
                          ></youtube>
                          <video
                            v-if="video.type === 'file'"
                            controls
                            class="videoarticle-responsive"
                          >
                            <source :src="video.id" type="video/mp4" />
                          </video>
                          <iframe
                            v-if="video.type == 'switch'"
                            :src="'https://tube.switch.ch/embed/' + video.id"
                            frameborder="0"
                            webkitallowfullscreen
                            mozallowfullscreen
                            allowfullscreen
                          ></iframe>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
                <div ref="anchor_subarticles" v-if="article.subarticles">
                  <h2
                    class="mt-10 mb-4"
                    v-html="$t('article.subarticles')"
                  ></h2>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        xl="4"
                        v-for="(item, i) in article.subarticles"
                        :key="'subarticles_' + i"
                      >
                        <ArticleSmallTeaser :article="item" />
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>

              <v-col cols="12" :md="4" class="px-10">
                <div class="px-md-4">
                  <v-responsive
                    :aspect-ratio="1"
                    v-if="article.illustration"
                    class="mb-9"
                  >
                    <v-img :src="article.illustration" aspect-ratio="1" contain> </v-img>
                  </v-responsive>

                  <!-- <v-btn
                    block
                    class="my-5 btn-article"
                    :class="article.category.color + '--text'"
                  >
                    Partager<v-icon right>mdi-share-variant</v-icon>
                  </v-btn> -->
                  <!-- <v-btn
                    block
                    class="btn-article white--text"
                    :class="article.category.color"
                    v-on:click="markAsFavorite(article.id)"
                  >
                    Epingler cette fiche<v-icon right>mdi-bookmark</v-icon>
                  </v-btn> -->

                  <div class="mt-3">
                    <v-btn
                      v-if="article.body"
                      width="100%"
                      :class="article.category.color + '--text text--darken-1'"
                      class="
												justify-start
												btn-anchor
												px-2
											"
                      @click="goto('anchor_content')"
                      v-html="$t('article.fiche_content')"
                    ></v-btn>
                    <v-btn
                      v-if="article.annexes"
                      width="100%"
                      :class="article.category.color + '--text text--darken-1'"
                      class="
												justify-start
												btn-anchor
												px-2
											"
                      @click="goto('anchor_doc')"
                      v-html="$t('article.useful_doc')"
                    ></v-btn>
                    <v-btn
                      v-if="article.links"
                      width="100%"
                      :class="article.category.color + '--text text--darken-1'"
                      class="
												justify-start
												btn-anchor
												px-2
											"
                      @click="goto('anchor_link')"
                      v-html="$t('article.useful_link')"
                    ></v-btn>
                    <v-btn
                      v-if="article.videos"
                      width="100%"
                      :class="article.category.color + '--text text--darken-1'"
                      class="
												justify-start
												btn-anchor
												px-2
											"
                      @click="goto('anchor_video')"
                      v-html="$t('article.video')"
                    ></v-btn>
                    <v-btn
                      v-if="objectives.main || objectives.secondary"
                      width="100%"
                      :class="article.category.color + '--text text--darken-1'"
                      class="
												justify-start
												btn-anchor
												px-2
											"
                      @click="goto('anchor_validateobj')"
                      v-html="$t('article.obj_validate')"
                    ></v-btn>
                  </div>
                  <div class="mt-8">
                    <v-chip-group column>
                      <router-link
                        class="chip"
                        v-for="(tag, k) in article.tags"
                        :key="'tag_' + k"
                        :to="{
                          name: 'tag',
                          params: { id: tag.id },
                        }"
                      >
                        <v-chip
                          class="white--text"
                          :color="article.category.color"
                        >
                          {{ tag.name }}
                        </v-chip>
                      </router-link>
                    </v-chip-group>
                  </div>
                  <div class="mt-7">
                    <a
                      :href="
                        'mailto:frimed@unifr.ch?subject=' +
                          $t('article.subject_suggestion') +
                          ' ' +
                          article.title
                      "
                      target="_blank"
                      class="
												font-weight-medium
												text--secondary
												text-decoration-none
												d-flex
												align-center
											"
                    >
                      <v-icon dense class="mr-3">
                        mdi-email
                      </v-icon>
                      {{ $t('general.error_report') }}
                    </a>
                  </div>
                  <div class="mt-12" v-if="article.last_update">
                    <p style="font-size: 90%; color: #999">
                      {{ $t('article.last_update') }} :
                      {{ article.last_update }}
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider style="border-color: rgba(0, 0, 0, 0.1)"></v-divider>
        <v-divider style="border-color: rgba(0, 0, 0, 0.1)"></v-divider>
        <v-card tile class="pt-3 pb-12 mb-12 px-md-12" ref="anchor_validateobj" v-if="article.category.no_advance !== '1'">
          <v-container class="pa-0 px-10 down-col">
            <v-row>
              <v-col cols="12" v-if="objectives.main || objectives.secondary">
                <h2 v-html="$t('article.obj_validate')"></h2>
              </v-col>
              <v-col cols="12" :md="12" v-if="objectives.main">
                <h3
                  :class="article.category.color + '--text'"
                  class="mb-2"
                  v-html="$t('article.main_obj')"
                ></h3>

                <v-row>
                  <v-col
                    v-for="(obj, id) in objectives.main"
                    :key="'amainobj' + id"
                    cols="12"
                    md="6"
                    class="py-0"
                  >
                    <v-checkbox
                      @change="switchObjectif(id)"
                      :label="obj.title"
                      :color="article.category.color"
                      :input-value="obj.status"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="12" :md="6" v-if="objectives.main">
                <h3 :class="article.category.color + '--text'" class="mb-8">
                  &nbsp;
                </h3>
                <ul>
                  <li
                    v-for="(obj, id) in objectives.main"
                    :key="'amainobj' + id"
                    :class="article.category.color + '--bullet'"
                  >
                    <v-checkbox
                      @change="switchObjectif(id)"
                      :label="obj.title"
                      :color="article.category.color"
                      :input-value="obj.status"
                      hide-details
                    ></v-checkbox>
                  </li>
                </ul> -->
              <!-- <h3
                  :class="article.category.color + '--text'"
                  class="mb-2"
                  v-html="$t('article.second_obj')"
                ></h3>
                <ul>
                  <li
                    v-for="(obj, id) in objectives.secondary"
                    :key="'asecobj' + id"
                    :class="article.category.color + '--bullet'"
                  >
                    <v-checkbox
                      @change="switchObjectif(id)"
                      :label="obj.title"
                      :color="article.category.color"
                      :input-value="obj.status"
                      value="Lorem_ipsum"
                      hide-details
                    ></v-checkbox>
                  </li>
                </ul> -->
              <!-- </v-col> -->

              <!-- <div class="px-4 py-4">
                <v-btn
                  block
                  class="btn-article white--text"
                  :color="article.category.color"
                  v-on:click="markAsFavorite()"
                >
                  > Valider tous les objectifs<v-icon right>mdi-check</v-icon>
                </v-btn>
              </div> -->

              <v-col cols="12" class="px-0 py-4 pt-8">
                <h2 class="px-4">
                  {{ $t('general.category_progress') }} "{{
                    article.maincategory.title
                  }}"
                </h2>
                <v-card-text class="pb-0">
                  <v-progress-linear
                    class="progressbar_custom article"
                    background-color="grey lighten-2"
                    :color="article.category.color"
                    rounded
                    height="45"
                    :value="score"
                  >
                    <template v-slot="{ value }">
                      <strong
                        class="
													grey--text
													text--darken-2
												"
                        >{{ Math.ceil(value)
                        }}<span class="percent">%</span></strong
                      >
                    </template>
                  </v-progress-linear>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </v-container>

    <div v-if="loading">
      <v-container class="mt-n12 pa-0 white relative skeleton_card">
        <v-skeleton-loader class="py-6 px-md-12 mb-12" type="card">
          <v-container class="pa-0">
            <v-row class="d-flex flex-column-reverse flex-md-row">
              <v-col cols="12" :md="8" class="px-10">
                <v-skeleton-loader
                  class="py-6 px-md-1 ma-0"
                  type="list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
                >
                </v-skeleton-loader>
              </v-col>

              <v-col cols="12" :md="4" class="px-10">
                <div class="px-4">
                  <v-skeleton-loader
                    class="py-6 px-md-2 ma-0"
                    type="button, button"
                  >
                  </v-skeleton-loader>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-skeleton-loader>
      </v-container>

      <!-- <v-container>
        <v-row>
          <v-col cols="12" md="8">
            <v-skeleton-loader
              class="mb-6"
              type="list-item-three-line, list-item-three-line"
            ></v-skeleton-loader>
          </v-col>

          <v-col cols="12" md="4">
            <v-skeleton-loader class="mb-6" type="actions"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container> -->
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import ArticleHeader from 'components/ArticleHeader'
import ArticleSmallTeaser from '@/components/ArticleSmallTeaser'

export default {
  props: ['id'],
  data() {
    return {
      score: 0,
      rating: 2,
      article: {},
      previous: null,
      objectives: [],
      next: null,
      articleImg: '',
      loading: true,
      uid: this.$store.state.user.profile.profile_uid,
    }
  },

  created() {
    api
      .getArticle(this.id, this.$store.getters.getProfile.uid)
      .then((response) => {
        this.article = response.data.article
        this.articleImg = this.article.illustration
        this.previous = response.data.previous
        this.next = response.data.next
        this.loading = false
        console.log(this.article);
        api.setLatestArticles(
          this.$store.getters.getProfile.uid,
          this.article.id
        )
        api.getScore(this.$store.getters.getProfile.uid).then((response) => {
          this.score = response.data.categories[this.article.maincategory.id]
            ? response.data.categories[this.article.maincategory.id].score
            : 0
        })
        api
          .getObjectives(this.id, this.$store.getters.getProfile.uid)
          .then((response) => {
            this.objectives = response.data
          })
      })


  },

  methods: {
    switchObjectif(id) {
      api
        .switchObjectif(this.id, id, this.$store.getters.getProfile.uid)
        .then((response) => {
          this.score = response.data
        })
    },
    markAsFavorite(id) {
      api.switchFavorite(this.id, this.$store.getters.getProfile.uid)
    },
    goto(refName) {
      var element = this.$refs[refName]
      if (this.typeOf(element) == 'object') {
        element = this.$refs[refName]['$el']
      }

      element.scrollIntoView({ behavior: 'smooth' })
    },
    typeOf(obj) {
      return {}.toString
        .call(obj)
        .split(' ')[1]
        .slice(0, -1)
        .toLowerCase()
    },
  },

  components: {
    ArticleHeader,
    ArticleSmallTeaser,
  },
}
</script>

<style lang="scss">
@import 'src/assets/css/color.scss';
@import 'src/assets/css/article_color.scss';
@import 'src/assets/css/progressbar.scss';

.videoarticle-responsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;

	iframe {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
  }
}

h1 {
  word-break: break-word !important;
}

.v-divider {
}

.btn-anchor {
  border-radius: 0px;
  background-color: transparent !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.btn-column-article {
  border-radius: 0;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  background-color: transparent !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);

  &:first-of-type {
    content: '';
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }

  .v-btn__content {
    justify-content: space-between;
    display: contents;
  }
}

.btn-article {
  .v-btn__content {
    justify-content: space-between;
    display: contents;
  }
}

.chip {
  text-decoration: none;
}

.down-col {
  h1 {
    font-size: 26px;
  }

  h2 {
    color: #1a1a1a;
    font-family: 'Roboto', serif !important;
    font-weight: 500;
    font-size: 20px;
  }

  h3 {
    font-family: 'Roboto', serif !important;
    font-size: 16px;
  }

  ul {
    list-style: none;
    padding: 0 !important;

    li {
      .v-input--selection-controls.v-input {
        padding-top: 5px;
        margin-top: 5px;
      }
    }
  }
}

.left-col-article {
  h1 {
    font-size: 26px;
  }

  h2 {
    color: #1a1a1a;
    font-family: 'Roboto', serif !important;
    font-weight: 500;
    font-size: 20px;
  }

  h3 {
    font-family: 'Roboto', serif !important;
    font-size: 16px;
  }

  ul {
    list-style: none;

    li {
      color: #545454;

      &:before {
        content: '\2022';
        font-size: 22px;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }

      &.competenceClinique--bullet:before {
        color: $competenceClinique;
      }
      &.traumatologie--bullet:before {
        color: $traumatologie;
      }
      &.cycleVie--bullet:before {
        color: $cycleVie;
      }
      &.troublesGeneraux--bullet:before {
        color: $troublesGeneraux;
      }
      &.santeSociete--bullet:before {
        color: $santeSociete;
      }
      &.maladiesPsychiques--bullet:before {
        color: $maladiesPsychiques;
      }
      &.laboratoire--bullet:before {
        color: $laboratoire;
      }
      &.maladieChroniques--bullet:before {
        color: $maladieChroniques;
      }
      &.dossiersPedago--bullet:before {
        color: $dossiersPedago;
      }
    }
  }

  .article-text {
    color: #545454;
  }
}

.article-text {
  h2 {
    font-weight: 600;
    // solor: 'articleCategory.field_color[0].value';
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 15px;
    font-weight: 600;
  }
  h6 {
    font-size: 15px;
    font-weight: 500;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Lora', serif;
  }
  p {
    font-size: 15px;
  }
  ul {
    margin-bottom: 16px;

    li {
      font-size: 15px;
    }
  }

  ol {
    margin-bottom: 16px;

    li {
      font-size: 15px;
    }
  }
  a {
    color: red;
    transition: all 0.2s;
    &:hover {
      color: black;
    }
  }
}
.link_list {
  // border: 1px solid red;
}

.triangle {
  &.corner_header {
    position: absolute;
    top: 7px;
    left: 15px;
    z-index: 1;
    font-size: 24px;
    font-family: 'Lora', serif !important;

    &:before {
      content: '';
      position: absolute;
      top: -7px;
      left: -15px;
      z-index: -1;
      border-style: solid;
      border-width: 80px 80px 0 0;
    }
  }
}

.skeleton_header {
  .v-skeleton-loader__image {
    height: 280px;
  }
}
.skeleton_card {
  z-index: 20;
  position: relative;
}

.v-skeleton-loader__button {
  width: 100%;
  margin-bottom: 10px;
}
.progresse_reactive {
  border-radius: 5px;
}
.objectives {
  width: 45%;
  float: left;
}
</style>
